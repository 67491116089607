import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {AuthenticationService} from './services/authentication.service';
import {JwtInterceptor} from './interceptor/token.interceptor';
import {ReadMoreComponent} from './components/read-more/read-more.component';
import {TruncatePipe} from "@app/shared/pipes/truncate-pipe/truncate.pipe";

@NgModule({
  declarations: [

    ReadMoreComponent,
    TruncatePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule,
    ReadMoreComponent,
  ],
  imports: [
    CommonModule,
  ],

})

export class SharedModule {
}
