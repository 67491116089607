// read-more.component.ts
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-read-more',
  template: `
    <p>{{isCollapsed ? (content | truncate:30) : content}}
    <span *ngIf="isCollapsed && content.length > 50" (click)="toggleView()"> Read more</span>
    <span *ngIf="!isCollapsed" (click)="toggleView()"> Read less</span>
    </p>
  `,
  styles: [`
    span {
      color: blue;
      cursor: pointer;
    }
  `]
})
export class ReadMoreComponent {
  @Input() content = '';
  isCollapsed = true;

  toggleView(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
