import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Role } from './roles';
import { LoginResponse, User } from '../user/user.types';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { UserService } from '../user/user.service';
import { ForgotPasswordRequest } from '@app/views/auth/components/shared/forgot-password-request.model';
import { ResetPasswordRequest } from '@app/views/auth/components/shared/reset-password-request.model';

@Injectable()
export class AuthService {
    private apiUrl = environment.plentyApi;

    private _authenticated: boolean = false;
    private _userRole: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    get token(): string {


        return localStorage.getItem('token') ?? '';
    }
    set token(token: string) {

        localStorage.setItem('token', token);
    }

    get user(): any {
        return JSON.parse(localStorage.getItem('user') ?? '');
    }
    set user(user: string) {
        localStorage.setItem('user', user);
    }

    get getRole$(): Observable<boolean> {
        return this._userRole.asObservable();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    // forgotPassword(email: string): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/forgot-password', email);
    // }

    forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Observable<any> {
        return this._httpClient.post(this.apiUrl + '/api/Account/ForgotPassword', forgotPasswordRequest);
    }

    /**
     * Reset password
     *
     * @param password
     */
    // resetPassword(password: string): Observable<any> {
    //     return this._httpClient.post('api/auth/reset-password', password);
    // }

    resetPassword(resetPasswordRequest: ResetPasswordRequest): Observable<any> {
        return this._httpClient.post(this.apiUrl + '/api/Account/ResetPassword', resetPasswordRequest);
    }

    changepassword(credentials: { current_password: string; new_password: string; new_confirm_password: string }): Observable<any> {
        return this._httpClient.post(this.apiUrl + 'api/user/password', credentials);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials): Observable<any> {

        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(this.apiUrl + '/api/account/authenticate', credentials).pipe(
            map((response: LoginResponse): any => {
                // Store the access token in the local storage
                this.token = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                this.user = JSON.stringify(response)


                // Store the user on the user service
                this._userService.user = { id: response.id, email: response.email, userName: response.userName }
            })
        );
    }





    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<boolean> {
        // Renew token
        return this._httpClient.get(this.apiUrl + 'api/user').pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((res: any) => {
                localStorage.setItem('user_info', JSON.stringify(res.data));
                this._userService.user = res.data;
                return of(true);
            }), shareReplay(1),
        );
    }

    /**
     * Sign out
     */
    signOut(): any {
        // Remove the access token from the local storage
        localStorage.removeItem('token');
        localStorage.removeItem('user_info');
        // Set the authenticated flag to false
        this._authenticated = false;

    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { firstName: string; lastName: string; email: string; userName: string, password: string, isAdmin: boolean }): Observable<any> {

        return this._httpClient.post(`${this.apiUrl}/api/account/register`, user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.token) {
            return of(false);
        }
        // Check the access token expire date
        return of(!AuthUtils.isTokenExpired(this.token));
    }

    checkAdmin(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.token) {
            return of(false);
        }

        const test = AuthUtils.isAdmin(this.token)
        const test1 = AuthUtils.isTokenExpired(this.token)
        console.log(test, "testtest");
        console.log(test1, "test1test1");

        // Check the access token expire date
        return of(AuthUtils.isAdmin(this.token) && !AuthUtils.isTokenExpired(this.token));
    }






    // isAdmin(): any{
    //     return this._userService.user$.pipe(
    //         map((res: User): any => {
    //             console.log(res,"RESSSSSSSSTEST");
    //             this._userRole.next(res.role.name === Role.Admin)
    //             return of(res.role.name === Role.Admin)
    //         }),

    //     );
    // }
}
