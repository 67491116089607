import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { AppLayoutComponent } from "./layout/app-layout/app-layout-component";

import { AUTH_LAYOUT_ROUTES } from "./routes/auth-layout.routes";
import { ADMIN_ROUTES, APP_LAYOUT_ROUTES } from './routes/app-layout.routes';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { AdminGuard } from './core/auth/guards/admin.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AppLayoutComponent,
        children: APP_LAYOUT_ROUTES,
        canActivateChild: [AuthGuard]
    },
    {
        path: 'admin',
        component: AppLayoutComponent,
        children: ADMIN_ROUTES,
        canActivateChild: [AdminGuard]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: AUTH_LAYOUT_ROUTES,
        canActivateChild: [NoAuthGuard]

    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
